
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import InvestmentPackageItem from './InvestmentPackageItem.vue';
import { listInvestmentPackage } from '@/apis/ApiContract';
export default defineComponent({
  components: {
    DefaultLayout,
    InvestmentPackageItem,
  },
  data: () => ({
    packageList: null,
    page: 1,
    limit: 10,
  }),
  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      listInvestmentPackage(this.page, this.limit)
        .then((res) => {
          this.packageList = res.data.data;
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
  },
});
