
import { defineComponent } from 'vue';
import ProgressBar from '@/components/Base/BaseProgresBar.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseAvatar from '@/components/Base/BaseAvatar.vue';
import ConstRouter from '@/constants/constRouter';
import * as HelperTime from '@/helpers/helperTime';
import * as Analytics from '@/services/analytics';

export default defineComponent({
  components: {
    ProgressBar,
    BaseButton,
    BaseAvatar,
  },
  props: {
    packageData: {
      type: Object,
    },
  },
  data: () => ({
    id: '1',
  }),
  computed: {
    percentInvest(): any {
      if (this.packageData) {
        return Math.min(
          Math.round(
            (this.packageData.investedAmount / this.packageData.amount) * 100,
          ),
          100,
        );
      }
      return null;
    },
  },
  methods: {
    handleClickBtn() {
      this.$router.push({
        name: ConstRouter.INVESTMENT_PACKAGE_DETAIL.name,
        params: { id: this.packageData?.id },
      });
      //analytics
      Analytics.eventK2A(
        'INVESTMENT',
        'DETAIL_POOL',
        'Xem chi tiết pool',
        this.packageData?.id,
      );
    },
    convertNumberTo2Digit(_number: number) {
      const number = _number + '';
      if (number.length < 2) {
        return '0' + number;
      }
      return _number;
    },
    hoursLeft(expireTime: string) {
      const duration = HelperTime.diff2Date(expireTime, new Date().toString());
      if (duration && duration.asSeconds > 0) {
        return this.convertNumberTo2Digit(duration.hours);
      }
      return 0;
    },
  },
});
